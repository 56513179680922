.home-section{
    min-height: 100vh;
    padding: 0 !important;
    position: relative;
    padding-top: 10vh;
}

.image-container{
    min-height: 100vh;
    width: 100%;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    opacity : 1;
    display: flex;
    align-items: center;
    filter: blur(3px);
}

#video{
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}

.main-home{
    height: 100%;
}

.home-container{
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.box-container{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: center;
    width: 100%;
    height: 60%;
}

.content-box{
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 25px;
    height: 60%;
}

.conten-box{
    background-color: rgba(0, 0, 0, 0.3);
    padding: 80px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 2px 2px #000;
    height: 60%;
}

.box-container p,h2,button{
    text-align: center;
    padding: 5px;
    color: black;
}

.button-container i{
   color: #f1583b;
}

.button-container a{
    margin-bottom: 15vh;
}

.box-container h2{
    font-size: 50px;
    font-weight: bold;
}

.box-container p{
    font-size: 28px;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 40%;
}

@media only screen and (max-width: 768px) {
    .box-container{
        width: 100%;
    }

    .content-box{
        padding-left: 10px;
        padding-right: 10px;
        height: 80%;
    }

    .box-container h2{
        font-size: 38px;
    }
    
    .box-container button{
        width: 50%;
        left: 90px;
        top: 640px;
    }
}
