.team-section {
  min-height: 100vh;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  /* height: 80%; */
  display: flex;
  justify-content: center;
}

.team-description {
  margin-top: 5vh;
  margin-bottom: 3vh;
  align-content: center;
}

.team-description h2 {
  font-size: 64px;
  font-weight: bold;
  color: black;
}

@media only screen and (max-width: 600px) {
  .team-description h2 {
    font-size: 46px;
  }
}
