.member{
    height: 50%;
    margin-bottom: 30px;
}

.member img{
    height: 150%;
    width: 150%;
    margin-left: 25px;
}

.member .image{
    height: 60%;
}

.member p{
    width: 100%;
    margin: 20px 0;
    color: dimgrey;
}

.member h3,p{
    text-align: center;
    margin: 20px 0;
}

.member h3{
    font-weight: bold;
}

.member button{
    padding: 10px 40px;
    border-radius: 25px;
    border: 0;
    background-color: green;
    position: relative;
    width: 50%;
    top:-30px;
    margin-left: 22%;
}