.blog-post-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 1000px;  /* Set a maximum width */
    margin: 0 auto;   /* Horizontal centering */
    padding: 1rem;    /* Optional: add some space around */
}

.blog-post-iframe {
    width: 100%;
    height: 100%;  /* Adjust this if you want specific height or make it dynamic */
    border: none;  /* Remove any default borders */
}