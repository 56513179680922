.contact-section{
    min-height: 100vh;
    border-bottom: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-section .container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-touch {
    margin-top: 6vh;
    margin-bottom: 12vh;
    width: 100%;
}

.contact-touch input{
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #dbdbdb;
}

.contact-touch textarea{
    padding: 15px;
    border: 1px solid #dbdbdb;
    min-height: 150px;
}

.submit-button{
    margin-top: 20px;
    padding: 10px 40px;
    border-radius: 25px;
    border: 0;
    background-color: #f1583b;
    color: white;
}

.get-in-touch-form{
    width: 100%;
}

form{
    height: 100%;
    width: 100%;
}

form .row{
    height: 100%;
}

.contact-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
}

.contact-text h2{
    color:black;
    font-size: 50px;
    font-weight: bold;
}

.contact-text p{
    text-align: center;
    color: #5f6060;
}

.map{
    height: 50vh;
    padding: 0 !important;
}

.visit-h2{
    font-size: 64px;
    font-weight: bold;
    text-align: center;
}

.visit-us{
    height: 18vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.map-section{
    background-color: black;
}

.get-in-touch-form .button-container{
    display: flex;
    justify-content: center;
    height: 80px;
}

@media only screen and (max-width: 600px) {
    .get-in-touch-form{
        margin-top: 30px;
    }

    .visit-h2{
        font-size: 46px;
    }

    .contact-text h2{
        font-size: 46px;
        width: 100%;
    }

    .contact-text{
        width: 90%;
    }

    .visit-us{
        margin: 0 !important;
    }

    .contact-touch{
        margin-top: 0;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px){
    .contact-touch{
        width: 100%;
    }

    .contact-text{
        width: 100%;
    }
}
