.footer-section{
    background-color: #5f6060;
    height: 300px;
}

.footer-section .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.footer-contacts-container{
    justify-content: space-between;
    height: 80%;
}

.footer-section .logo{
    height: 40px;
    width: 40px;
}

.footer-section .logo img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.footer-section .social{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 50%
}

.get-in-touch p{
    color: white;
    height: 25%;
    margin: 0;
}

.get-in-touch h3{
    font-weight: bold;
    color: white;
    height: 25%;
    margin: 0;
}

.icons i{
    color: #f1583b;
    margin-right: 30px;
    font-size: 26px;
}

@media only screen and (max-width: 768px) {
    .footer-section{
        height: 80vh;
    } 

    .footer-section .social{
        margin: 0;
    }

    .get-in-touch{
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px){
    .footer-section{
        height: 35vh;
    } 
}