.navbar-nav{
    /* position: relative; */
    /* right: 0; */
    /* flex-grow: 10; */
    /* justify-content: space-between; */
}

.nav-item a{
    color:#5f6060;
    font-weight: bold;
}

.nav-item a:hover{
    color:#f1583b;
}

.navbar{
    height: 100%;
}

.navbar h1{
    color:white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    padding: 0;
}

.navbar h1 img{
    height: 50px;
}

.navbar button{
    border: 1px solid black;
    width: 40%;
    padding-bottom: 12px;
    padding-top: 6px;
}

.navbar button span{
    margin: 0 auto;
    padding: 0;
}

.navbar button i{
    padding: 0;
    color: black;
}

.navbar-toggler-icon{
    font-size: 16px;
}

@media only screen and (max-width: 768px) {
    .navbar-toggler{
        margin: auto;
        margin-right: 0;
        padding: 0;
    }

    .navbar-nav li{
        float: right;
        margin: auto;
    }
    
    .navbar-nav li a{
        font-size: 30px;
    }

    .navbar{
        padding: 0;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .navbar-toggler{
        margin: auto;
        margin-right: 0;
        padding: 0;
        width: 40%;
    }

    .navbar-nav li{
        margin: auto;
    }

    .navbar{
        padding: 0;
    }

    .navbar-nav li a{
        font-size: 24px;
        padding: 5px 0;
    }
  }
