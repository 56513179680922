.services-section{
    min-height: 100vh;
    border-bottom: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services-description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.services-description h2{
    color: black;
    font-size: 50px;
    font-weight: bold;
    width: 100%;
}

.services{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}

.services-description p{
    color: #5f6060;
    text-align: center;
    font-size: 18px;
    width: 70%;
}

@media only screen and (max-width: 768px) {
    .services{
        flex-direction: column;
    }

    .services-description p{
        width: 90%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .services-section{
        min-height: auto;
    }
}