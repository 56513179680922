.about-section{
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 2px solid black;
}

.about-section .container{
    display: flex;
    align-items: center;
}

.about-content{
    margin:auto;
    padding: 3rem 0;
}

.about-description{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 20px;
}

.about-h2{
    color:black;
    font-size: 50px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
}

.about-image-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 400px;
    overflow: hidden;
}

.about-image{
    width: 110%;
    height: 100%;
}

.about-image-mobile{
    display: none;
}

.about-information{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-description p{
    text-align: justify;
    font-size: 20px;
    color: #5f6060
}

.about-description{
    margin-bottom: 20px;
}

@media only screen and (min-width: 1440px) {
    .about-image{
        width: 110%;
    }  
}

@media only screen and (max-width: 767px) {
    .about-description h3{
        text-align: center;
    }

    .about-description p{
        text-align: center;
        width: 100%;
    }

    .about-image{
        display: none;
    }
    
    .about-image-mobile{
        display: block;
        width: 100%;
        height: 100%;

    }

    .about-image-wrapper{
        height: auto;
    }

    .about-description .about-h2{
        text-align: center;
    }

    .about-h2{
        font-size: 46px;
        width: 100%;
    }

    .about-description{
        padding: 0;
    }

    .about-content{
        margin-top: 100px;
    }

    .about-section{
        min-height: auto;
    }

}

