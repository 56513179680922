.contact-option{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
}

.contact-option span{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #f1583b;
    border-radius: 60%;
    color: white;
    font-size: 12px;
}

.contact{
    padding-right: 0 !important;
    height: fit-content;
}

.center{
    justify-content: center;
}

.contact-option .icon{
    width: 50px;
    height: 50px;
}

.contact-option .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    width: 75%;
}

.contact-option .content h4,p{
    text-align: start;
    color: white;
}

.contact-option .content p{
    /* margin:0; */
    color: white;
    /* font-size: 20px; */
}

.contact-option .content h4{
    margin-bottom: 5px;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
}

.contact-option i{
    color: white;
    font-size: 18px;
}

@media only screen and (max-width: 768px) {
    .contact-info{
        height: 100%;
    }

    .contact{
        display: flex;
        align-items: center;
    }

    .contact-option{
        width: 100%;
    } 
}

@media only screen and (max-width: 1024px) {
    .contact{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
    }

    .contact-option{
        display: flex;
        align-items: center;
        width: 100%;
    } 
}
