/* Container for individual blog post */
.blog-post-item {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    max-width: 1000px;  /* Set a maximum width */
    margin: 1rem auto;  /* Adjust margins to center the div */
}

.blog-post-item:hover {
    background-color: #f9f9f9;
}

/* Blog post image */
.blog-post-image {
    flex: 0 0 120px; /* Width of the image */
    margin-left: 1rem;
}

.blog-post-image img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

/* Content of the blog post */
.blog-post-content {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
}

/* Blog post title */
.blog-post-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

/* Blog post preview */
.blog-post-preview {
    margin-bottom: 0.5rem;
    color: #333;
}

/* Blog post author */
.blog-post-author {
    font-size: 0.9rem;
    color: #777;
}
